// import { configureStore } from '@reduxjs/toolkit';
// import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
// import { persistReducer } from 'redux-persist';
// import { rootPersistConfig, rootReducer } from './rootReducer';

// // ----------------------------------------------------------------------

// const store = configureStore({
//   reducer: persistReducer(rootPersistConfig, rootReducer),
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//       immutableCheck: false,
//     }),
// });

// const { dispatch } = store;

// const useSelector = useAppSelector;

// const useDispatch = () => useAppDispatch();

// export { store, dispatch, useSelector, useDispatch };


import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './slices/chatSlice';

export const store = configureStore({
  reducer: {
    chat: chatReducer,
  },
});